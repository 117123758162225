import { template as template_19b78ee99aa24dd487de5ff3f75555ef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_19b78ee99aa24dd487de5ff3f75555ef(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
