import { template as template_6a83aea6c38249d48053daa5981aefdd } from "@ember/template-compiler";
const FKControlMenuContainer = template_6a83aea6c38249d48053daa5981aefdd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
