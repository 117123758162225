import { template as template_e0ec043103c44548b0d3b43420ac87cd } from "@ember/template-compiler";
const SidebarSectionMessage = template_e0ec043103c44548b0d3b43420ac87cd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
